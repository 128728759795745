import React, { useEffect, useState } from "react";
import { Box, Typography, Button, TextField } from "@mui/material";
import SectionDisplayAdmin from "../Section/SectionDisplayAdmin"; 
import AddSectionForm from "../Section/AddSectionForm";

interface Section {
    _id: string;
    title: string;
    description: string;
    images: string[];
}

interface SectionTitle {
    _id: string;
    titre: string;
    description: string;
}

interface AdminSectionProps {
    sectionsUrl: string;
    getTitleUrl: string;
    putTitleUrl: string;
    color: string;
    startIndex: number;
}

const AdminSection: React.FC<AdminSectionProps> = ({ sectionsUrl, getTitleUrl, putTitleUrl, color, startIndex }) => {
    const [sections, setSections] = useState<Section[]>([]);
    const [sectionTitle, setSectionTitle] = useState<SectionTitle | null>(null);
    const [editingSectionId, setEditingSectionId] = useState<string | null>(null);
    const [isAddingAtTop, setIsAddingAtTop] = useState(false);
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [newTitle, setNewTitle] = useState("");
    const [newDescription, setNewDescription] = useState("");

    const fetchSections = async () => {
        try {
            const response = await fetch(sectionsUrl);
            if (!response.ok) {
                throw new Error("Failed to fetch sections");
            }
            const data = await response.json();
            setSections(data);
        } catch (error) {
            console.error("Error fetching sections:", error);
        }
    };

    const fetchSectionTitle = async () => {
        try {
            const response = await fetch(getTitleUrl);
            if (!response.ok) {
                throw new Error("Failed to fetch section title");
            }
            const data = await response.json();
            setSectionTitle(data);
        } catch (error) {
            console.error("Error fetching section title:", error);
        }
    };

    useEffect(() => {
        fetchSections();
        fetchSectionTitle();
        const interval = setInterval(() => {
            fetchSections();
            fetchSectionTitle();
        }, 5000); // Rafraîchir toutes les 5 secondes

        return () => clearInterval(interval); // Nettoyer l'intervalle
    }, [sectionsUrl, getTitleUrl, putTitleUrl]);

    const handleAddSection = (newSection: Section, position: number) => {
        setSections((prevSections) => {
            const newSections = [...prevSections];
            newSections.splice(position, 0, newSection);
            setIsAddingAtTop(false);
            return newSections;
        });
    };

    const handleEditSection = async (id: string, formData: FormData) => {
        try {
            const response = await fetch(`${sectionsUrl}/${id}`, {
                method: 'PUT',
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Failed to update section");
            }

            const updatedSection = await response.json();

            setSections((prevSections) =>
                prevSections.map((section) =>
                    section._id === id ? updatedSection : section
                )
            );
            setEditingSectionId(null);
        } catch (error) {
            console.error("Error updating section:", error);
        }
    };

    const handleDeleteSection = async (id: string) => {
        try {
            const response = await fetch(`${sectionsUrl}/${id}`, {
                method: 'DELETE',
            });

            if (!response.ok) {
                throw new Error("Failed to delete section");
            }

            setSections((prevSections) =>
                prevSections.filter((section) => section._id !== id)
            );
        } catch (error) {
            console.error("Error deleting section:", error);
        }
    };

    const handleStartEditing = (id: string) => {
        setEditingSectionId(id);
    };

    const handleSubmitEdit = (id: string, formData: FormData) => {
        handleEditSection(id, formData);
    };

    const handleEditTitleAndDescription = async () => {
        try {
            const response = await fetch(`${putTitleUrl}/${sectionTitle?._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    titre: newTitle,
                    description: newDescription,
                }),
            });

            if (!response.ok) {
                throw new Error("Failed to update section title");
            }

            const updatedTitle = await response.json();
            setSectionTitle(updatedTitle);
            setIsEditingTitle(false);
        } catch (error) {
            console.error("Error updating section title:", error);
        }
    };

    const startEditingTitleAndDescription = () => {
        if (sectionTitle) {
            setNewTitle(sectionTitle.titre);
            setNewDescription(sectionTitle.description);
            setIsEditingTitle(true);
        }
    };

    return (
        <Box sx={{ padding: "40px" }}>
            <Box
                sx={{
                    padding: "40px",
                    textAlign: "left",
                    backgroundColor: "#f9f9f9", //blanc
                }}
            >
                {sectionTitle && (
                    <>
                        {isEditingTitle ? (
                            <>
                                <TextField
                                    label="Titre"
                                    value={newTitle}
                                    onChange={(e) => setNewTitle(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="Description"
                                    value={newDescription}
                                    onChange={(e) => setNewDescription(e.target.value)}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    margin="normal"
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleEditTitleAndDescription}
                                    sx={{ marginTop: "10px", marginRight: "10px" }}
                                >
                                    Enregistrer
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => setIsEditingTitle(false)}
                                    sx={{ marginTop: "10px" }}
                                >
                                    Annuler
                                </Button>
                            </>
                        ) : (
                            <>
                                <Typography variant="h4" component="h1" gutterBottom>
                                    {sectionTitle.titre}
                                </Typography>
                                <Typography variant="body1">
                                    {sectionTitle.description}
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={startEditingTitleAndDescription}
                                    sx={{ marginTop: "20px" }}
                                >
                                    Modifier
                                </Button>
                            </>
                        )}
                    </>
                )}
            </Box>

            <Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setIsAddingAtTop(!isAddingAtTop)}
                >
                    Ajouter une section en haut
                </Button>
            </Box>

            {isAddingAtTop && (
                <AddSectionForm
                    apiUrl={sectionsUrl}
                    onAddSection={(newSection: Section) => handleAddSection(newSection, 0)}
                    onCancel={() => setIsAddingAtTop(false)}
                    position={0}
                />
            )}

            {sections.length === 0 ? (
                <Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
                    <Typography variant="body1">
                        Aucune section disponible. Ajoutez une nouvelle section.
                    </Typography>
                </Box>
            ) : (
                sections.map((section, index) => (
                    <SectionDisplayAdmin
                        apiUrl={sectionsUrl}
                        key={section._id}
                        title={section.title}
                        description={section.description}
                        images={section.images}
                        index={index + startIndex}
                        onEdit={() => handleStartEditing(section._id)}
                        onDelete={() => handleDeleteSection(section._id)}
                        onSubmitEdit={(formData: FormData) => handleSubmitEdit(section._id, formData)}
                        onAddSection={(newSection: Section) => handleAddSection(newSection, index + 1)}
                        position={index + 1}
                        color={color}
                    />
                ))
            )}
        </Box>
    );
};

export default AdminSection;