import React from "react";
import Section from "../Section/Section";
import API_BASE_URL from '../../api/config';

const Entreprise = () => {
  return (
    <Section
      apiUrl={`${API_BASE_URL}/entreprise`}
      sectionTitleApiUrl={`${API_BASE_URL}/sectiontitles/entreprise`}
      color="#f4e1d2"
      startIndex={0} 
    />
  );
};

export default Entreprise;
