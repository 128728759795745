import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import SectionDisplay from "./SectionDisplay";

interface Section {
  _id: string;
  title: string;
  description: string;
  images: string[];
}

interface SectionTitle {
  _id: string;
  nomsection: string;
  titre: string;
  description: string;
}

const Section = ({ apiUrl, sectionTitleApiUrl, color, startIndex }: { apiUrl: string; sectionTitleApiUrl: string, color: string, startIndex: number }) => {
  const [sections, setSections] = useState<Section[]>([]);
  const [sectionTitle, setSectionTitle] = useState<SectionTitle | null>(null);

  useEffect(() => {
    const fetchSections = async () => {
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error("Failed to fetch sections");
        }
        const data = await response.json();
        setSections(data);
      } catch (error) {
        console.error("Error fetching sections:", error);
      }
    };

    const fetchSectionTitle = async () => {
      try {
        const response = await fetch(sectionTitleApiUrl);
        if (!response.ok) {
          throw new Error("Failed to fetch section title");
        }
        const data = await response.json();
        setSectionTitle(data);
      } catch (error) {
        console.error("Error fetching section title:", error);
      }
    };

    fetchSections();
    fetchSectionTitle();
  }, [apiUrl, sectionTitleApiUrl]);

  return (
    <Box sx={{ padding: "40px" }}>
      <Box
        sx={{
          padding: "40px",
          textAlign: "left",
          backgroundColor: "#f9f9f9",
        }}
      >
        {sectionTitle && (
          <>
            <Typography variant="h4" component="h1" gutterBottom>
              {sectionTitle.titre}
            </Typography>
            <Typography variant="body1">{sectionTitle.description}</Typography>
          </>
        )}
      </Box>

      {sections.map((section, index) => (
        <SectionDisplay
          key={section._id}
          title={section.title}
          description={section.description}
          images={section.images}
          index={index + startIndex}
          color={color} 
        />
      ))}
    </Box>
  );
};

export default Section;