import Appbar from "../../components/general/Appbar";
import Footer from "../../components/general/Footer";
import Entreprise from "../../components/Entreprise/Entreprise";
import AdminEntreprise from "../../components/Entreprise/AdminEntreprise";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

interface DecodedToken {
    exp?: number;
    role?: string;
    [key: string]: any;
}

const checkAndClearLocalStorage = () => {
    const token = localStorage.getItem('token');
    if (token) {
        try {
            const decodedToken: DecodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;

            if (decodedToken.exp && decodedToken.exp < currentTime) {
                localStorage.removeItem('token');
            } 
        } catch (error) {
            localStorage.removeItem('token');
        }
    } 
};

export const EntreprisePage = () => {
const navigate = useNavigate();
const [isAdmin, setIsAdmin] = useState(false);

useEffect(() => {
    checkAndClearLocalStorage();

    const token = localStorage.getItem('token');
    if (token) {
        try {
            const decodedToken: any = jwtDecode(token); 
            if (decodedToken && decodedToken.role === 'admin') {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
                navigate('/login');
            }
        } catch (error) {
            setIsAdmin(false);
            navigate('/entreprise');
        }
    } else {
        setIsAdmin(false);
        navigate('/entreprise');
    }
}, [navigate]);

    return(
        <>
            <Appbar />
            {isAdmin ? <AdminEntreprise /> : <Entreprise />}
            <Footer />
        </>
    )
}

export default EntreprisePage;
