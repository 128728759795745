import Confirmation from "../../components/Messagerie/Confirmation";
import Appbar from "../../components/general/Appbar";
import Footer from "../../components/general/Footer";

export const ConfirmationCandidature = () => {
    return(
        <>
            <Appbar />
            <Confirmation 
            title="Merci pour votre candidature !" 
            description="Nous avons bien reçu votre candidature. Nous vous contacterons sous peu."
            />
            <Footer />
        </>
    )
}

export default ConfirmationCandidature;