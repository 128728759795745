import React from 'react';
import LoginRegister from '../../components/loginRegister/loginRegister';
import AppBar from '../../components/general/Appbar';

const loginRegister = () => {
	return(
		<>	
			<AppBar />
			<LoginRegister />
		</>
	);
}

export default loginRegister;
