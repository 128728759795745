import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import API_BASE_URL from '../../api/config';

const CUSTOM_BREAKPOINT = 1100;

interface SectionTitle {
    _id: string;
    nomsection: string;
    titre: string;
    description: string;
}

interface BoxContent {
    id: number;
    title: string;
    text: string;
    imgSrc: string;
    link: string;
}

const Maintenance = () => {
    const [hoveredBox, setHoveredBox] = useState(null);
    const [sectionTitle, setSectionTitle] = useState<SectionTitle | null>(null);
    const [boxContents, setBoxContents] = useState<BoxContent[]>([]);

    const handleMouseEnter = (boxId: any) => {
        setHoveredBox(boxId);
    };

    const handleMouseLeave = () => {
        setHoveredBox(null);
    };

    useEffect(() => {

        const fetchSectionTitle = async () => {
            try {
              const response = await fetch(`${API_BASE_URL}/sectiontitles/maintenance`); 
              if (!response.ok) {
                throw new Error("Failed to fetch section title");
              }
              const data = await response.json();
              setSectionTitle(data); // Mettez à jour l'état local avec le titre de la section récupéré depuis l'API
            } catch (error) {
              console.error("Error fetching section title:", error);
            }
          };

        const fetchBoxContents = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/maintenance`);
                if (!response.ok) {
                    throw new Error("Failed to fetch box contents");
                }
                const data = await response.json();

                // Manually set id and link
                const manualBoxContents = [
                    { id: 1, link: '/depanner' },
                    { id: 2, link: '/entretenir' },
                    { id: 3, link: '/securiser' }
                ];

                const formattedData = data.map((item: any, index: number) => ({
                    id: manualBoxContents[index].id,
                    title: item.title,
                    text: item.description,
                    imgSrc: `${API_BASE_URL}/${item.image}`,
                    link: manualBoxContents[index].link
                }));

                setBoxContents(formattedData);
            } catch (error) {
                console.error("Error fetching box contents:", error);
            }
        };

          fetchSectionTitle();
          fetchBoxContents();
    }, []);

    

    return (
        <Box sx={{ padding: '40px' }}>
            <Box 
                sx={{ 
                    padding: '40px', 
                    textAlign: 'left',  
                    backgroundColor: '#f9f9f9', // fond blanc
                }}
            >
                {sectionTitle && (
                <>
                    <Typography variant="h4" component="h1" gutterBottom>
                    {sectionTitle.titre}
                    </Typography>
                    <Typography variant="body1">
                    {sectionTitle.description}
                    </Typography>
                </>
                )}
            </Box>

            <Box 
                sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    flexWrap: 'wrap', 
                    [`@media (max-width:${CUSTOM_BREAKPOINT}px)`]: {
                        flexDirection: 'column',
                    },
                }}
            >
                {boxContents.map((box) => (
                    <Box
                        key={box.id}
                        component={Link}
                        to={box.link}
                        sx={{ 
                            width: '32%',
                            height: '515px',  // hauteur des images
                            cursor: 'pointer', 
                            position: 'relative',
                            backgroundColor: '#e0e0e0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            overflow: 'hidden',
                            textAlign: 'center',
                            marginTop: '30px',
                            [`@media (max-width:${CUSTOM_BREAKPOINT}px)`]: {
                                width: '100%',
                            },
                            textDecoration: 'none', // pour supprimer la ligne de soulignement du lien
                            color: 'black', // pour que le texte du lien soit toujours noir
                            '&:visited': {
                                color: 'black', // couleur du lien visité
                            },
                            '&:hover': {
                                textDecoration: 'none', // pas de soulignement au survol
                            },
                            '&:active': {
                                color: 'black', // couleur du lien actif
                            },
                        }}
                        onMouseEnter={() => handleMouseEnter(box.id)}
                        onMouseLeave={handleMouseLeave}
                    >
                        {hoveredBox === box.id ? (
                            <Box>         
                                <Typography variant="body1" sx={{ position: 'relative', zIndex: 1, fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '20px' }}>
                                    {box.title}
                                </Typography>
                                <Typography variant="body1" sx={{ position: 'relative', zIndex: 1, marginLeft: '12px', marginRight: '12px' }}>
                                    {box.text}
                                </Typography>
                            </Box>
                        ) : (
                            <>
                                <img src={box.imgSrc} alt={`image ${box.id}`} style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute' }} />
                                <Typography 
                                    variant="h6" 
                                    sx={{ 
                                        position: 'relative', 
                                        zIndex: 1, 
                                        color: 'black', 
                                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                        padding: '6px',
                                        width: '100%',
                                        textAlign: 'center',
                                        textTransform: 'uppercase',
                                        fontSize: '1.3rem !important', 
                                    }}
                                >
                                    {box.title}
                                </Typography>
                            </>
                        )}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default Maintenance;