import { Box, Typography, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import API_BASE_URL from '../../api/config';

const CUSTOM_BREAKPOINT = 1100;

interface SectionTitle {
    _id: string;
    nomsection: string;
    titre: string;
    description: string;
}

interface BoxContent {
    id: number;
    title: string;
    text: string;
    imgSrc: string;
}

const AdminMaintenance = () => {
    const [hoveredBox, setHoveredBox] = useState(null);
    const [sectionTitle, setSectionTitle] = useState<SectionTitle | null>(null);
    const [boxContents, setBoxContents] = useState<BoxContent[]>([]);
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [newTitle, setNewTitle] = useState("");
    const [newDescription, setNewDescription] = useState("");
    const [editingSection, setEditingSection] = useState<number | null>(null);
    const [editingTitle, setEditingTitle] = useState("");
    const [editingDescription, setEditingDescription] = useState("");
    const [newSectionImage, setNewSectionImage] = useState<File | null>(null);

    const handleMouseEnter = (boxId: any) => {
        setHoveredBox(boxId);
    };

    const handleMouseLeave = () => {
        setHoveredBox(null);
    };

    useEffect(() => {

        const fetchSectionTitle = async () => {
            try {
              const response = await fetch(`${API_BASE_URL}/sectiontitles/maintenance`); 
              if (!response.ok) {
                throw new Error("Failed to fetch section title");
              }
              const data = await response.json();
              setSectionTitle(data); // Mettez à jour l'état local avec le titre de la section récupéré depuis l'API
            } catch (error) {
              console.error("Error fetching section title:", error);
            }
          };

        const fetchBoxContents = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/maintenance`);
                if (!response.ok) {
                    throw new Error("Failed to fetch box contents");
                }
                const data = await response.json();

                // Manually set id and link
                const manualBoxContents = [
                    { id: 1, link: '/depanner' },
                    { id: 2, link: '/entretenir' },
                    { id: 3, link: '/securiser' }
                ];

                const formattedData = data.map((item: any, index: number) => ({
                    id: item._id, 
                    title: item.title,
                    text: item.description,
                    imgSrc: `${API_BASE_URL}/${item.image}`,
                    link: manualBoxContents[index].link
                }));

                setBoxContents(formattedData);
            } catch (error) {
                console.error("Error fetching box contents:", error);
            }
        };

          fetchSectionTitle();
          fetchBoxContents();
    }, []);

    const handleEditTitleAndDescription = async () => {
        try {
          const response = await fetch(`${API_BASE_URL}/sectiontitles/${sectionTitle?._id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              titre: newTitle,
              description: newDescription,
            }),
          });
    
          if (!response.ok) {
            throw new Error("Failed to update section title");
          }
    
          const updatedTitle = await response.json();
          setSectionTitle(updatedTitle);
          setIsEditingTitle(false);
        } catch (error) {
          console.error("Error updating section title:", error);
        }
      };
    
    const startEditingTitleAndDescription = () => {
        if (sectionTitle) {
          setNewTitle(sectionTitle.titre);
          setNewDescription(sectionTitle.description);
          setIsEditingTitle(true);
        }
      };

    const startEditingSection = (sectionId: number, section: BoxContent) => {
        setEditingSection(sectionId);
        setEditingTitle(section.title);
        setEditingDescription(section.text);
        setNewSectionImage(null);
    };

    const handleEditSection = async (sectionId: number) => {
        const formData = new FormData();
        formData.append('title', editingTitle);
        formData.append('description', editingDescription);
        if (newSectionImage) {
            formData.append('image', newSectionImage);
        }

        try {
            const response = await fetch(`${API_BASE_URL}/maintenance/${sectionId}`, {
                method: 'PUT',
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Failed to update section");
            }

            const updatedSection = await response.json();
            setBoxContents((prevBoxContents) =>
                prevBoxContents.map((box) =>
                    box.id === sectionId
                        ? { ...box, title: updatedSection.title, text: updatedSection.description, imgSrc: `${API_BASE_URL}/${updatedSection.image}` }
                        : box
                )
            );
            setEditingSection(null);
        } catch (error) {
            console.error("Error updating section:", error);
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setNewSectionImage(e.target.files[0]);
        }
    };

    
    

    return (
        <Box sx={{ padding: '40px' }}>
            <Box 
                sx={{ 
                    padding: '40px', 
                    textAlign: 'left',  
                    backgroundColor: '#f9f9f9', // fond blanc
                }}
            >
                {sectionTitle && (
                <>
                    {isEditingTitle ? (
                    <>
                        <TextField
                        label="Titre"
                        value={newTitle}
                        onChange={(e) => setNewTitle(e.target.value)}
                        fullWidth
                        margin="normal"
                        />
                        <TextField
                        label="Description"
                        value={newDescription}
                        onChange={(e: any) => setNewDescription(e.target.value)}
                        fullWidth
                        multiline
                        rows={4}
                        margin="normal"
                        />
                        <Button
                        variant="contained"
                        color="primary"
                        onClick={handleEditTitleAndDescription}
                        sx={{ marginTop: "10px", marginRight: "10px" }}
                        >
                        Enregistrer
                        </Button>
                        <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setIsEditingTitle(false)}
                        sx={{ marginTop: "10px" }}
                        >
                        Annuler
                        </Button>
                    </>
                    ) : (
                    <>
                        <Typography variant="h4" component="h1" gutterBottom>
                        {sectionTitle.titre}
                        </Typography>
                        <Typography variant="body1">
                        {sectionTitle.description}
                        </Typography>
                        <Button
                        variant="contained"
                        color="primary"
                        onClick={startEditingTitleAndDescription}
                        sx={{ marginTop: "20px" }}
                        >
                        Modifier
                        </Button>
                    </>
                    )}
                </>
                )}
            </Box>

            <Box 
                sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    flexWrap: 'wrap', 
                    [`@media (max-width:${CUSTOM_BREAKPOINT}px)`]: {
                        flexDirection: 'column',
                    },
                }}
            >
                {boxContents.map((box) => (
                    <Box
                        key={box.id}
                        sx={{ 
                            width: '32%',
                            height: '515px',  // hauteur des boxes
                            cursor: 'pointer', 
                            position: 'relative',
                            backgroundColor: '#e0e0e0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            overflow: 'hidden',
                            textAlign: 'center',
                            marginTop: '30px',
                            [`@media (max-width:${CUSTOM_BREAKPOINT}px)`]: {
                                width: '100%',
                            },
                            textDecoration: 'none', // pour supprimer la ligne de soulignement du lien
                            color: 'black', // pour que le texte du lien soit toujours noir
                            '&:visited': {
                                color: 'black', // couleur du lien visité
                            },
                            '&:hover': {
                                textDecoration: 'none', // pas de soulignement au survol
                            },
                            '&:active': {
                                color: 'black', // couleur du lien actif
                            },
                        }}
                        onMouseEnter={() => handleMouseEnter(box.id)}
                        onMouseLeave={handleMouseLeave}
                    >
                        {editingSection === box.id ? (
                            <Box
                            sx={{ 
                                padding: '8px', // Ajoute de l'espace interne
                                margin: '8px',  // Ajoute de l'espace externe
                            }}
                            >
                                <TextField
                                    label="Titre"
                                    value={editingTitle}
                                    onChange={(e) => setEditingTitle(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="Description"
                                    value={editingDescription}
                                    onChange={(e) => setEditingDescription(e.target.value)}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    margin="normal"
                                />
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    style={{ marginTop: "10px" }}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleEditSection(box.id)}
                                    sx={{ marginTop: "10px", marginRight: "10px" }}
                                >
                                    Enregistrer
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => setEditingSection(null)}
                                    sx={{ marginTop: "10px" }}
                                >
                                    Annuler
                                </Button>
                            </Box>
                        ) : (
                            <Box>
                                {hoveredBox === box.id ? (
                                    <Box>
                                        <Typography variant="body1" sx={{ position: 'relative', zIndex: 1, fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '20px' }}>
                                            {box.title}
                                        </Typography>
                                        <Typography variant="body1" sx={{ position: 'relative', zIndex: 1, marginLeft: '12px', marginRight: '12px' }}>
                                            {box.text}
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => startEditingSection(box.id, box)}
                                            sx={{ marginTop: "10px" }}
                                        >
                                            Modifier
                                        </Button>
                                    </Box>
                                ) : (
                                    <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                                        <img 
                                            src={box.imgSrc} 
                                            alt={box.title} 
                                            style={{ 
                                                width: '100%', 
                                                height: '100%', 
                                                objectFit: 'cover',
                                                display: 'block',
                                              
                                                
                                            }} 
                                        />
                                        <Box 
                                            sx={{ 
                                                position: 'absolute', 
                                                top: 0, 
                                                left: 0, 
                                                width: '100%', 
                                                height: '100%', 
                                                backgroundColor: hoveredBox === box.id ? 'rgba(0, 0, 0, 0.5)' : 'transparent', 
                                                display: 'flex', 
                                                alignItems: 'center', 
                                                justifyContent: 'center', 
                                                color: 'white', 
                                                opacity: hoveredBox === box.id ? 1 : 0, 
                                                transition: 'opacity 0.3s ease-in-out' 
                                            }}
                                        >
                                            <Typography variant="h5" component="h2">
                                                {box.title}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        )}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default AdminMaintenance;