import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import "./styles/styles.scss"
import variables from './styles/variables.module.scss';

import HomePageDisplay from './pages/HomePage/HomePageDisplay';
import ContactPage from './pages/Contact/ContactPage';
import JoinUsPage from './pages/Messagerie/JoinUsPage';
import MessagePage from './pages/Messagerie/MessagePage';
import MaintenancePage from './pages/Maintenance/MaintenancePage';
import ClimatisationPage from './pages/Climatisation/ClimatisationPage';
import RealisationPage from './pages/Realisation/RealisationPage';
import ElectricitePage from './pages/Electricite/ElectricitePage';
import DepannerPage from './pages/Depanner/DepannerPage';
import EntretenirPage from './pages/Entretenir/EntretenirPage';
import SecuriserPage from './pages/Securiser/SecuriserPage';
import LoginRegisterPage from './pages/loginRegister/loginRegister';
import LoginForm from './components/loginRegister/loginForm';
import ConfirmationCandidature from './pages/Messagerie/ConfirmationCandidature';
import ConfirmationMessage from './pages/Messagerie/ConfirmationMessage';
import EntreprisePage from './pages/Entreprise/EntreprisePage';

let theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: variables.primaryColor,
    },
    secondary: {
      main: variables.secondaryColor,
    },
    text: {
      primary: variables.textColor,
      disabled: variables.greyColor,
    },
    background: {
      default: variables.whiteColor,
      paper: variables.whiteColor,
    },
  },
  breakpoints: {
    values: {
      xs: parseInt(variables.breakpointXS, 10),
      sm: parseInt(variables.breakpointSM, 10),
      md: parseInt(variables.breakpointMD, 10),
      lg: parseInt(variables.breakpointLG, 10),
      xl: parseInt(variables.breakpointXL, 10),
    },
  },
  typography: {
    h1: {
      fontFamily: [
        'Roboto',
        'Helvetica',
        'serif',
      ].join(','),
    },
    h2: {
      fontFamily: [
        'Roboto',
        'Helvetica',
        'serif',
      ].join(','),
    },
    h3: {
      fontFamily: [
        'Roboto',
        'Helvetica',
        'sans-serif',
      ].join(','),
    },
    h4: {
      fontFamily: [
        'Roboto',
        'Helvetica',
        'sans-serif',
      ].join(','),
    },
    h5: {
      fontFamily: [
        'Roboto',
        'Helvetica',
        'sans-serif',
      ].join(','),
    },
    h6: {
      fontFamily: [
        'Roboto',
        'Helvetica',
        'sans-serif',
      ].join(','),
    },
    subtitle1: {
      fontFamily: [
        'Roboto',
        'Helvetica',
        'sans-serif',
      ].join(','),
    },
    body1: {
      fontFamily: [
        'Roboto',
        'Helvetica',
        'sans-serif',
      ].join(','),
    },
    caption: {
      fontFamily: [
        'Roboto',
        'Helvetica',
        'sans-serif',
      ].join(','),
    },
    button: {
      fontFamily: [
        'Roboto',
        'Helvetica',
        'serif',
      ].join(','),
      fontWeight: "bold",
    }
  },
  shape: {
    borderRadius: 0
  },
});

theme = responsiveFontSizes(theme);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/homepage" />} />
          <Route path="/homepage" element={<HomePageDisplay />} />
          <Route path="/location" element={<ContactPage />} />
          <Route path="/join-us" element={<JoinUsPage />} />
          <Route path="/contact" element={<MessagePage />} />
          <Route path="/maintenance" element={<MaintenancePage />} />
          <Route path="/climatisation" element={<ClimatisationPage />} />
          <Route path="/realisations" element={<RealisationPage />} />
          <Route path="/electricite" element={<ElectricitePage />} />
          <Route path="/connexion" element={<LoginRegisterPage />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/candidat/confirmation" element={<ConfirmationCandidature />} />
          <Route path="/contact/confirmation" element={<ConfirmationMessage />} />
          <Route path="/depanner" element={<DepannerPage />} />
          <Route path="/entretenir" element={<EntretenirPage />} />
          <Route path="/securiser" element={<SecuriserPage />} />
          <Route path="/entreprise" element={<EntreprisePage />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
