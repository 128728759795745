import React from "react";
import AdminSection from "../Section/AdminSection";
import API_BASE_URL from '../../api/config';

const AdminEntreprise = () => {
  return (
    <AdminSection
      sectionsUrl={`${API_BASE_URL}/entreprise`}
      getTitleUrl={`${API_BASE_URL}/sectiontitles/entreprise`}
      putTitleUrl={`${API_BASE_URL}/sectiontitles`}
      color="#f4e1d2"
      startIndex={0}
    />
  );
};

export default AdminEntreprise;
