import { Box } from "@mui/material";
import { Typography, Button, TextField, MenuItem, InputLabel, FormControl, Select, Alert } from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import EmailSettingsBox from './EmailSetting';
import API_BASE_URL from '../../api/config';

interface SectionTitle {
    _id: string;
    nomsection: string;
    titre: string;
    description: string;
  }

const TitleBox = () => {

    const [sectionTitle, setSectionTitle] = useState<SectionTitle | null>(null);
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [newTitle, setNewTitle] = useState("");
    const [newDescription, setNewDescription] = useState("");

    useEffect(() => {
    
        const fetchSectionTitle = async () => {
          try {
            const response = await fetch(`${API_BASE_URL}/sectiontitles/candidature`);
            if (!response.ok) {
              throw new Error("Failed to fetch section title");
            }
            const data = await response.json();
            setSectionTitle(data);
          } catch (error) {
            console.error("Error fetching section title:", error);
          }
        };
    
        fetchSectionTitle();
      }, [sectionTitle]);

    const handleEditTitleAndDescription = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/sectiontitles/${sectionTitle?._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    titre: newTitle,
                    description: newDescription,
                }),
            });

            if (!response.ok) {
                throw new Error("Failed to update section title");
            }

            const updatedTitle = await response.json();
            setSectionTitle(updatedTitle);
            setIsEditingTitle(false);
        } catch (error) {
            console.error("Error updating section title:", error);
        }
    };

    const startEditingTitleAndDescription = () => {
        if (sectionTitle) {
            setNewTitle(sectionTitle.titre);
            setNewDescription(sectionTitle.description);
            setIsEditingTitle(true);
        }
    };

    return (
        <Box 
            sx={{ 
                padding: '40px', 
                textAlign: 'left', 
                border: '1px solid #ccc', 
                borderRadius: '8px', 
                backgroundColor: '#f9f9f9', 
                marginBottom: '20px'
            }}
        >
            {sectionTitle && (
                    <>
                        {isEditingTitle ? (
                            <>
                                <TextField
                                    label="Titre"
                                    value={newTitle}
                                    onChange={(e) => setNewTitle(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="Description"
                                    value={newDescription}
                                    onChange={(e) => setNewDescription(e.target.value)}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    margin="normal"
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleEditTitleAndDescription}
                                    sx={{ marginTop: "10px", marginRight: "10px" }}
                                >
                                    Enregistrer
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => setIsEditingTitle(false)}
                                    sx={{ marginTop: "10px" }}
                                >
                                    Annuler
                                </Button>
                            </>
                        ) : (
                            <>
                                <Typography variant="h4" component="h1" gutterBottom>
                                    {sectionTitle.titre}
                                </Typography>
                                <Typography variant="body1">
                                    {sectionTitle.description}
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={startEditingTitleAndDescription}
                                    sx={{ marginTop: "20px" }}
                                >
                                    Modifier
                                </Button>
                            </>
                        )}
                    </>
                )}
        </Box>
    );
};

const FormBox = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        objet: '',
        nom: '',
        prenom: '',
        telephone: '',
        email: '',
        cv: null,
        lettre: null,
        message: ''
    });

    const [objet, setObjet] = React.useState('');
    type AlertType = {
        severity: 'success' | 'info' | 'warning' | 'error';
        message: string;
      };
    const [alert, setAlert] = useState<AlertType | null>(null);
    

    const handleObjetChange = (event: any) => {
        setObjet(event.target.value);
        const { value } = event.target;
        setFormData({
            ...formData,
            objet: value
        });
    };

    const handleFileChange = (event: any) => {
        const { name, files } = event.target;
        setFormData({
            ...formData,
            [name]: files[0]
        });
    };

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };


    const handleSubmit = async (event: any) => {
        event.preventDefault();

        setAlert(null);

        // Vérifier si tous les champs requis sont remplis
        if (!formData.objet || !formData.nom || !formData.prenom || !formData.telephone || !formData.email || !formData.message) {
            setAlert({ severity: 'error', message: 'Veuillez remplir tous les champs requis.' });
            return;
        }

        console.log(formData);

        const data = new FormData();
        data.append('objet', objet); // Ajouter le champ 'objet'
        data.append('nom', formData.nom); // Ajouter le champ 'nom'
        data.append('prenom', formData.prenom); // Ajouter le champ 'prenom'
        data.append('telephone', formData.telephone); // Ajouter le champ 'telephone'
        data.append('email', formData.email); // Ajouter le champ 'email'
        data.append('message', formData.message); // Ajouter le champ 'message'
        if (formData.cv) {
            data.append('cv', formData.cv); // Ajouter le fichier CV
        }
        if (formData.lettre) {
            data.append('lettre', formData.lettre); // Ajouter le fichier lettre de motivation
        }

        try {
            const response = await axios.post(`${API_BASE_URL}/candidatures/submit`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data' // Spécifier le type de contenu pour FormData
                }
            });
            console.log(response.data); 
            // Gérer la réponse du backend ici si nécessaire
            setAlert({ severity: 'success', message: 'Candidature envoyée avec succès.' });
            navigate('/candidat/confirmation');
            // Réinitialiser le formulaire après soumission réussie si nécessaire
            setFormData({
                objet: '',
                nom: '',
                prenom: '',
                telephone: '',
                email: '',
                cv: null,
                lettre: null,
                message: ''
            });
        } catch (error) {
            setAlert({ severity: 'error', message: 'Erreur lors de l\'envoi de la candidature.' });
            console.error(error);
        }
    };

    return (
        <Box 
            sx={{ 
                padding: '40px', 
                textAlign: 'left', 
                border: '1px solid #ccc', 
                borderRadius: '8px', 
                backgroundColor: '#e0e0e0' // Fond gris
            }}
        >
            <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="objet-label">Objet</InputLabel>
                    <Select
                        labelId="objet-label"
                        value={objet}
                        onChange={handleObjetChange}
                        label="Objet"
                    >
                        <MenuItem value={'Stage'}>Stage</MenuItem>
                        <MenuItem value={'Offre emploi'}>Offre d'emploi</MenuItem>
                        <MenuItem value={'Autre'}>Autre</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    fullWidth
                    label="Nom"
                    name="nom"
                    value={formData.nom}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Prénom"
                    name="prenom"
                    value={formData.prenom}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Téléphone"
                    name="telephone"
                    value={formData.telephone}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                />
                <Box sx={{ marginTop: '16px' }}>
                    <InputLabel htmlFor="cv">CV</InputLabel>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                        <Button variant="contained" component="label" color="primary" sx={{ marginRight: '10px' }}>
                            Choisir le fichier
                            <input
                                id="cv"
                                name="cv"
                                type="file"
                                accept=".pdf"
                                onChange={handleFileChange}
                                hidden
                            />
                        </Button>
                        <Typography variant="body1">
                            {formData.cv ? (formData.cv as File).name : 'Aucun fichier sélectionné'}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ marginTop: '16px', marginBottom: '16px' }}>
                    <InputLabel htmlFor="lettre">Lettre de motivation</InputLabel>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                        <Button variant="contained" component="label" color="primary" sx={{ marginRight: '10px' }}>
                            Choisir le fichier
                            <input
                                id="lettre"
                                name="lettre"
                                type="file"
                                accept=".pdf"
                                onChange={handleFileChange}
                                hidden
                            />
                        </Button>
                        <Typography variant="body1">
                            {formData.lettre ? (formData.lettre as File).name : 'Aucun fichier sélectionné'}
                        </Typography>
                    </Box>
                </Box>
                <TextField
                    fullWidth
                    label="Message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    multiline
                    rows={4} //nombre de lignes 
                />
                {alert && (
                <Alert severity={alert.severity} sx={{ marginTop: '8px' }}>
                    {alert.message}
                </Alert>
            )}
                <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ marginTop: '20px' }}>
                    Envoyer
                </Button>
            </Box>
        </Box>
    );
};

const AdminJoinUs = () => {
    return (
        <Box sx={{ padding: '20px' }}>
            <TitleBox />
            <EmailSettingsBox /> 
            <FormBox />
        </Box>
    );
};

export default AdminJoinUs;