import React from "react";
import Section from "../Section/Section";
import API_BASE_URL from '../../api/config';

const Entretenir = () => {
  return (
    <Section
      apiUrl={`${API_BASE_URL}/entretenir`}
      sectionTitleApiUrl={`${API_BASE_URL}/sectiontitles/entretenir`}
      color="#8ca3a3"
      startIndex={0}
    />
  );
};

export default Entretenir;