import React from "react";
import AdminSection from "../Section/AdminSection";
import API_BASE_URL from '../../api/config';

const AdminSecuriser = () => {
  return (
    <AdminSection
      sectionsUrl={`${API_BASE_URL}/securiser`}
      getTitleUrl={`${API_BASE_URL}/sectiontitles/securiser`}
      putTitleUrl={`${API_BASE_URL}/sectiontitles`}
      color="#b2ad7f"
      startIndex={0}
    />
  );
};

export default AdminSecuriser;
