import React from "react";
import Section from "../Section/Section";
import API_BASE_URL from '../../api/config';

const Depanner = () => {
  return (
    <Section
      apiUrl={`${API_BASE_URL}/depanner`}
      sectionTitleApiUrl={`${API_BASE_URL}/sectiontitles/depanner`}
      color="#c6bcb6"
      startIndex={1}
    />
  );
};

export default Depanner;