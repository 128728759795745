import React, { useEffect, useState } from 'react';
import Appbar from "../general/Appbar"
import Footer from "../general/Footer"
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import styles from "../../styles/HomePage/homepage.module.scss";
import axios from 'axios';
import API_BASE_URL from '../../api/config';

const CUSTOM_BREAKPOINT = 1100;
const LARGE_SCREEN_HEIGHT = 800;
const SMALL_SCREEN_HEIGHT = 450;

const HomePage = () => {

    const logos = [
        '/qualifelec.png',
        '/mff.png',
        '/KNX.png',
        '/esser.png',
        '/daikin.jpg'
    ];

    const [sections, setSections] = useState([
        {
            id: 1,
            title: 'Entreprise',
            backgroundImage: '', // Placeholder, will be replaced with fetched image
            buttonLink: '/entreprise',
            buttonText: 'Découvrir'
        },
        {
            id: 2,
            title: 'Maintenance',
            backgroundImage: '', // Placeholder
            buttonLink: '/maintenance',
            buttonText: 'Découvrir'
        },
        {
            id: 3,
            title: 'Electricité',
            backgroundImage: '', // Placeholder
            buttonLink: '/electricite',
            buttonText: 'Découvrir'
        },
        {
            id: 4,
            title: 'Climatisation',
            backgroundImage: '', // Placeholder
            buttonLink: '/climatisation',
            buttonText: 'Découvrir'
        }
    ]);

    useEffect(() => {
        // Fonction pour récupérer les images
        const fetchImages = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/homepage`);
                const images = response.data; // Supposons que les données soient directement accessibles

                // Mettre à jour les sections avec les URLs d'images récupérées
                const updatedSections = sections.map((section: any, index: any) => {
                    // On associe les images récupérées en fonction de l'ordre des sections
                    if (images[index]) {
                        return {
                            ...section,
                            backgroundImage: `${API_BASE_URL}/${images[index].image}`
                        };
                    }
                    return section;
                });

                setSections(updatedSections);
            } catch (error) {
                console.error('Erreur lors de la récupération des images:', error);
            }
        };

        fetchImages();
    }, []);
    

    return (
        <Box className={styles.homepageContainer}>
            <Appbar />
            <Box className={styles.voitureContainer}
                sx={{
                    backgroundImage: 'url("/voitures.jpeg")',
                    minHeight: {
                        xs: `${SMALL_SCREEN_HEIGHT}px`, // default
                        [`@media (min-width:${CUSTOM_BREAKPOINT}px)`]: {
                            minHeight: `${LARGE_SCREEN_HEIGHT}px`
                        }
                    },
                }}
            />
            <Box className={styles.logobar}>
                {logos.map((logo, index) => (
                    <Box
                        key={index}
                        component="img"
                        src={logo}
                        alt={`Qualification Logo ${index + 1}`}
                        className={styles.logo}
                    />
                ))}
            </Box>
            <Box className={styles.sectionsContainer}>
                {sections.map((section: any) => (
                    <Box
                        key={section.id}
                        className={styles.section}
                        sx={{ backgroundImage: `url(${section.backgroundImage})` }}
                    >
                        <Typography variant="h4" className={styles.sectionTitle}>
                            {section.title}
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            component={Link}
                            to={section.buttonLink}
                            className={styles.sectionButton}
                            sx={{ 
                                marginTop: '25px'
                            }}
                        >
                            {section.buttonText}
                        </Button>
                    </Box>
                ))}
            </Box>
            <Footer />
        </Box>
    );
};

export default HomePage;