import React from "react";
import Section from "../Section/Section";
import API_BASE_URL from '../../api/config';

const Securiser = () => {
  return (
    <Section
      apiUrl={`${API_BASE_URL}/securiser`}
      sectionTitleApiUrl={`${API_BASE_URL}/sectiontitles/securiser`}
      color="#b2ad7f"
      startIndex={1}
    />
  );
};

export default Securiser;
