import React, { useState } from "react";
import { Box, Typography, Button, TextField, InputLabel } from "@mui/material";
import AddSectionForm from "./AddSectionForm";
import API_BASE_URL from '../../api/config';

const CUSTOM_BREAKPOINT = 1200;

const SectionDisplayAdmin = ({
  apiUrl, 
  title,
  description,
  images,
  index,
  onEdit,
  onDelete,
  onSubmitEdit,
  onAddSection,
  position,
  color,
}: {
  apiUrl: string;
  title: string;
  description: string;
  images: string[];
  index: number;
  onEdit: () => void;
  onDelete: () => void;
  onSubmitEdit: (formData: FormData) => void;
  onAddSection: (newSection: any) => void;
  position: number;
  color: string;
}) => {
  const isImageLeft = index % 2 === 0;
  const isSingleImage = images.length === 1;
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [editTitle, setEditTitle] = useState(title);
  const [editDescription, setEditDescription] = useState(description);
  const [editImages, setEditImages] = useState(images);
  const [newImages, setNewImages] = useState<FileList | null>(null);
  const [error, setError] = useState("");
  const [image1, setImage1] = useState<File | null>(null);
  const [image2, setImage2] = useState<File | null>(null);

  const handleSave = async () => {
    try {
      const formData = new FormData();
      formData.append("title", editTitle);
      formData.append("description", editDescription);
      formData.append("position", position.toString());
      
      // Ajouter les images individuelles si elles sont sélectionnées
      if (image1) {
        formData.append("images", image1);
      }
      if (image2) {
        formData.append("images", image2);
      }

      // Ajouter les images existantes si aucune nouvelle image n'est sélectionnée
      if (!image1 && !image2) {
        for (let i = 0; i < editImages.length; i++) {
          const response = await fetch(`${API_BASE_URL}/${editImages[i]}`);
          const blob = await response.blob();
          formData.append("images", blob, editImages[i]);
        }
      }

      await onSubmitEdit(formData);

      setIsEditing(false);
    } catch (error) {
      console.error("Erreur lors de l'enregistrement de la section modifiée :", error);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
    onEdit();
  };

  const handleAddSection = (newSection: any) => {
    onAddSection(newSection);
    setIsAdding(false);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, setImage: React.Dispatch<React.SetStateAction<File | null>>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]; 
      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setError("Veuillez sélectionner un fichier image valide (JPEG ou PNG).");
        setImage(null);
      } else {
        setError("");
        setImage(file);
      }
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          padding: "40px",
          backgroundColor: color,
          marginTop: "35px",
          flexDirection: {
            xs: "column",
            custom: isImageLeft ? "row" : "row-reverse",
          },
          alignItems: { xs: "center", custom: "flex-start" },
          [`@media (min-width:${CUSTOM_BREAKPOINT}px)`]: {
            flexDirection: isImageLeft ? "row" : "row-reverse",
            alignItems: "flex-start",
          },
        }}
      >
        <Box
          sx={{
            textAlign: { xs: "center", custom: "left" },
            marginBottom: { xs: "25px", custom: "0" },
            [`@media (min-width:${CUSTOM_BREAKPOINT}px)`]: {
              textAlign: "left",
              marginBottom: "0",
              marginRight: isImageLeft ? "30px" : "0px",
              marginLeft: isImageLeft ? "0px" : "40px",
            },
          }}
        >
          {isEditing ? (
            <>
              <TextField
                label="Titre"
                value={editTitle}
                onChange={(e) => setEditTitle(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Description"
                value={editDescription}
                onChange={(e) => setEditDescription(e.target.value)}
                fullWidth
                multiline
                rows={4}
                margin="normal"
              />
              <Box sx={{ marginTop: "16px" }}>
                <InputLabel htmlFor="edit-image1">Image 1</InputLabel>
                <input
                  id="edit-image1"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, setImage1)}
                  style={{ display: "block", marginTop: "8px", marginBottom: "10px" }}
                />
                <InputLabel htmlFor="edit-image2">Image 2</InputLabel>
                <input
                  id="edit-image2"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, setImage2)}
                  style={{ display: "block", marginTop: "8px", marginBottom: "10px" }}
                />
                {error && (
                  <Typography color="error" variant="body2">
                    {error}
                  </Typography>
                )}
              </Box>
              <Button
                onClick={handleSave}
                variant="contained"
                color="primary"
                sx={{ marginTop: "10px", marginRight: "10px" }}
              >
                Enregistrer
              </Button>
              <Button
                onClick={() => setIsEditing(false)}
                variant="contained"
                color="secondary"
                sx={{ marginTop: "10px" }}
              >
                Annuler
              </Button>
            </>
          ) : (
            <>
              <Typography
                variant="h5"
                component="h2"
                gutterBottom
                sx={{ marginTop: { custom: "25px", xs: "0" } }}
              >
                {title}
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{ marginTop: "20px", marginBottom: "25px", marginRight: { custom: "30px", xs: "0" }, [`@media (min-width:${CUSTOM_BREAKPOINT}px)`]: { marginLeft: "4px", marginRight: "4px" } }}
              >
                {description}
              </Typography>
              <Button
                onClick={handleEdit}
                variant="contained"
                color="primary"
                sx={{ marginTop: "20px", marginRight: "10px" }}
              >
                Modifier
              </Button>
              <Button
                onClick={onDelete}
                variant="contained"
                color="secondary"
                sx={{ marginTop: "20px" }}
              >
                Supprimer
              </Button>
            </>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", custom: "row" },
            alignItems: "center",
            [`@media (min-width:${CUSTOM_BREAKPOINT}px)`]: {
              flexDirection: "row",
              justifyContent: isImageLeft ? "flex-end" : "flex-start",
              width: isSingleImage ? "50%" : "100%",
            },
          }}
        >
          {images.map((image, idx) => (
            <Box
              key={idx}
              component="img"
              src={`${API_BASE_URL}/${image}`}
              alt={`Description de l'image ${idx + 1}`}
              sx={{
                width: { xs: "100%", custom: "380px" },
                height: "auto",
                marginRight: idx === 0 && images.length === 2 ? "20px" : "0",
                marginLeft: idx === 0 && images.length === 2 ? "20px" : "0",
                marginBottom: idx === 0 && images.length === 2 ? "20px" : "0",
                [`@media (min-width:${CUSTOM_BREAKPOINT}px)`]: {
                  width: "380px",
                  height: "300px",
                  marginRight: idx === 0 && images.length === 2 ? "30px" : "0",
                  marginLeft: idx === 0 && images.length === 2 ? "0px" : "0",
                  marginBottom: "0",
                },
              }}
            />
          ))}
        </Box>
      </Box>

        {isAdding ? (
          <AddSectionForm
            apiUrl={apiUrl} 
            onAddSection={handleAddSection}
            onCancel={() => setIsAdding(false)}
            position={position}
          />
        ) : (
        <Button
          onClick={() => setIsAdding(true)}
          variant="contained"
          color="primary"
          sx={{ marginTop: "20px" }}
        >
          Ajouter une section
        </Button>
      )}
    </Box>
  );
};

export default SectionDisplayAdmin;