import React from "react";
import AdminSection from "../Section/AdminSection";
import API_BASE_URL from '../../api/config';

const AdminElectricite = () => {
  return (
    <AdminSection
      sectionsUrl={`${API_BASE_URL}/climatisation`}
      getTitleUrl={`${API_BASE_URL}/sectiontitles/climatisation`}
      putTitleUrl={`${API_BASE_URL}/sectiontitles`}
      color="#b1cbbb"
      startIndex={0}
    />
  );
};

export default AdminElectricite;