import React from "react";
import { Box, Typography } from "@mui/material";
import API_BASE_URL from '../../api/config';

const CUSTOM_BREAKPOINT = 1200;

const SectionDisplay = ({
  title,
  description,
  images,
  index,
  color
}: {
  title: string;
  description: string;
  images: string[];
  index: number;
  color: string
}) => {
  const isImageLeft = index % 2 === 0;
  const isSingleImage = images.length === 1;

  return (
    <Box
      sx={{
        display: "flex",
        padding: "40px",
        backgroundColor: color,
        marginTop: "35px",
        flexDirection: {
          xs: "column",
          custom: isImageLeft ? "row" : "row-reverse"
        },
        alignItems: { xs: "center", custom: "flex-start" },
        [`@media (min-width:${CUSTOM_BREAKPOINT}px)`]: {
          flexDirection: isImageLeft ? "row" : "row-reverse",
          alignItems: "flex-start",
        }
      }}
    >
      <Box
        sx={{
          textAlign: { xs: "center", custom: "left" },
          marginBottom: { xs: "25px", custom: "0" },
          [`@media (min-width:${CUSTOM_BREAKPOINT}px)`]: {
            textAlign: "left",
            marginBottom: "0",
            marginRight: isImageLeft ? "30px" : "px",
            marginLeft: isImageLeft ? "0px" : "40px",
          }
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{ marginTop: "0", [`@media (min-width:${CUSTOM_BREAKPOINT}px)`]: { marginTop: "25px", marginLeft: "4px", marginRight: "4px" } }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ marginTop: "20px", marginBottom: "25px", marginRight: { custom: "30px", xs: "0" }, [`@media (min-width:${CUSTOM_BREAKPOINT}px)`]: { marginLeft: "4px", marginRight: "4px" } }}
        >
          {description}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", custom: "row" },
          alignItems: "center",
          [`@media (min-width:${CUSTOM_BREAKPOINT}px)`]: {
            flexDirection: "row",
            justifyContent: isImageLeft ? "flex-end" : "flex-start",
            width: isSingleImage ? "50%" : "100%",
          }
        }}
      >
        {images.map((image, idx) => (
          <Box
            key={idx}
            component="img"
            src={`${API_BASE_URL}/${image}`}
            alt={`Description de l'image ${idx + 1}`}
            sx={{
              width: { xs: "100%", custom: "380px" },
              height: "auto",
              marginRight: idx === 0 && images.length === 2 ? "20px" : "0", 
              marginLeft: idx === 0 && images.length === 2 ? "20px" : "0",
              marginBottom: idx === 0 && images.length === 2 ? "20px" : "0", 
              [`@media (min-width:${CUSTOM_BREAKPOINT}px)`]: {
                width: "380px",
                height: "300px",
                marginRight: idx === 0 && images.length === 2 ? "30px" : "0",
                marginLeft: idx === 0 && images.length === 2 ? "0px" : "0",
                marginBottom: "0",
              }
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SectionDisplay;