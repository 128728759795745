import React from "react";
import Section from "../Section/Section";
import API_BASE_URL from '../../api/config';

const Realisation = () => {
  return (
    <Section
      apiUrl={`${API_BASE_URL}/realisations`}
      sectionTitleApiUrl={`${API_BASE_URL}/sectiontitles/realisation`}
      color="#dac292" 
      startIndex={0}
    />
  );
};

export default Realisation;