import { Box, Typography, Divider, Accordion, AccordionSummary, AccordionDetails, useMediaQuery, Theme, styled } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import variables from '../../styles/variables.module.scss';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import styles from '../../styles/general/footer.module.scss';

export default function Footer() {
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    
    const StyledAccordion = styled(Accordion)(({ theme }) => ({
      backgroundColor: 'transparent',
      boxShadow: 'none',
      '& .MuiAccordionSummary-root': {
          padding: 0,
          minHeight: 'unset',
          '& .MuiTypography-root': {
              margin: 0,
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
              marginLeft: '8px', // Ajuster la marge ici pour rapprocher l'icône du texte
          },
      },
  }));
    
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-around',
          alignItems: 'center',
          backgroundColor: variables.primaryColor,
          width: '100%',
          padding: '20px',
          boxSizing: 'border-box'
        }}
      >

        {/* Section Liens utiles*/}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            flex: 1,
            textAlign: 'left',
            padding: '20px',
            marginLeft: { xs: 0, md: '160px' },
          }}
        >
          {isSmallScreen ? (
          <StyledAccordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
              <Typography variant="h6" color="white">Liens utiles</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">
                <a href="/homepage" style={{ color: 'white', textDecoration: 'none' }}>Accueil</a>
              </Typography>
              <Typography variant="body1">
                <a href="/entreprise" style={{ color: 'white', textDecoration: 'none' }}>Qui sommes nous ?</a>
              </Typography>
              <Typography variant="body1">
                <a href="/realisations" style={{ color: 'white', textDecoration: 'none' }}>Nos réalisations</a>
              </Typography>
              <Typography variant="body1">
                <a href="/join-us" style={{ color: 'white', textDecoration: 'none' }}>Rejoignez nous !</a>
              </Typography>
              <Typography variant="body1">
                <a href="/contact" style={{ color: 'white', textDecoration: 'none' }}>Contactez nous !</a>
              </Typography>
            </AccordionDetails>
        </StyledAccordion>
        ) : (
          <>
            <Typography variant="body1" component="div">
              <a href="/homepage" style={{ color: 'white', textDecoration: 'none' }}>Acceuil</a>
            </Typography>
            <Typography variant="body1" component="div">
              <a href="/entreprise" style={{ color: 'white', textDecoration: 'none' }}>Qui sommes nous ?</a>
            </Typography>
            <Typography variant="body1" component="div">
              <a href="/realisations" style={{ color: 'white', textDecoration: 'none' }}>Nos réalisations</a>
            </Typography>
            <Typography variant="body1" component="div">
              <a href="/join-us" style={{ color: 'white', textDecoration: 'none' }}>Rejoignez nous !</a>
            </Typography>
            <Typography variant="body1" component="div">
              <a href="/contact" style={{ color: 'white', textDecoration: 'none' }}>Contactez nous !</a>
            </Typography>
          </>
        )}
        </Box>

        <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', md: 'block' }, borderColor: 'white' }} />
        {/* Trait horizontal pour petits écrans */}
        <Box sx={{ display: { xs: 'block', md: 'none' }, width: '100%', borderTop: '1px solid white', margin: '10px 0' }} />

        {/* Section Contact */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            flex: 1,
            textAlign: 'left',
            padding: '30px'
          }}
        >
          <Box display="flex" alignItems="center" color="white" style={{ marginTop: '5px' }}>
            <PhoneIcon />
            <Typography variant="body1" component="div" style={{ marginLeft: '8px' }}>
            <a href="tel:+33492925240" style={{ textDecoration: 'none', color: 'inherit' }}>
              04 92 92 52 40
            </a>
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" color="white" style={{ marginTop: '10px' }}>
            <EmailIcon />
            <Typography variant="body1" component="div" style={{ marginLeft: '8px' }}>
              <a href="mailto:set@set-elec-clim.com" style={{ textDecoration: 'none', color: 'inherit' }}>
                set@set-elec-clim.com
              </a>
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" color="white" style={{ marginTop: '10px' }}>
            <LocationOnIcon />
            <Typography variant="body1" component="div" style={{ marginLeft: '8px' }}>
            <a
              href="https://www.google.com/maps/place/Societe+Electro+Thermique/@43.6074506,6.9516823,18.26z/data=!4m6!3m5!1s0x12cc284e4a3a5e13:0x96015c3c933264fc!8m2!3d43.6073916!4d6.9512437!16s%2Fg%2F1thpb24n?entry=ttu"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              460 Avenue de la Quiéra Zone de l'Argile - Lot 30 - 06370 MOUANS SARTOUX
            </a>
            </Typography>
          </Box>
        </Box>

        <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', md: 'block' }, borderColor: 'white' }} />
        {/* Trait horizontal pour petits écrans */}
        <Box sx={{ display: { xs: 'block', md: 'none' }, width: '100%', borderTop: '1px solid white', margin: '10px 0' }} />

        {/* Section Logo */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flex: 1,
            textAlign: 'center',
            padding: '20px',
            marginRight: { xs: 0, md: '160px' },
          }}
        >
        <img src="/set-logo2.png" alt="Logo" style={{ maxWidth: '160px' }} />

        </Box>
        
      </Box>
    );
  }