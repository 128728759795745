import React from "react";
import Section from "../Section/Section";
import API_BASE_URL from '../../api/config';

const Electricite = () => {
  return (
    <Section
      apiUrl={`${API_BASE_URL}/electricite`}
      sectionTitleApiUrl={`${API_BASE_URL}/sectiontitles/electricite`}
      color="#92a8d1" 
      startIndex={1}
    />
  );
};

export default Electricite;