import React from "react";
import AdminSection from "../Section/AdminSection";
import API_BASE_URL from '../../api/config';

const AdminRealisation = () => {
  return (
    <AdminSection
      sectionsUrl={`${API_BASE_URL}/realisations`}
      getTitleUrl={`${API_BASE_URL}/sectiontitles/realisation`}
      putTitleUrl={`${API_BASE_URL}/sectiontitles`}
      color="#dac292"
      startIndex={0}
    />
  );
};

export default AdminRealisation;