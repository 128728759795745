import { Box, Typography } from "@mui/material";
import React from "react";

interface ConfirmationProps {
    title: string;
    description: string;
}

const Confirmation: React.FC<ConfirmationProps> = ({ title, description }) => {
    return (
        <Box sx={{ padding: "40px" }}>
            <Box
                sx={{
                    padding: "40px",
                    textAlign: "left",
                    backgroundColor: "#f9f9f9", // fond blanc
                }}
            >
                <Typography variant="h4" component="h1" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="body1">
                    {description}
                </Typography>
            </Box>
        </Box>
    );
};

export default Confirmation;