import React from "react";
import AdminSection from "../Section/AdminSection";
import API_BASE_URL from '../../api/config';

const AdminDepanner = () => {
  return (
    <AdminSection
      sectionsUrl={`${API_BASE_URL}/depanner`}
      getTitleUrl={`${API_BASE_URL}/sectiontitles/depanner`}
      putTitleUrl={`${API_BASE_URL}/sectiontitles`}
      color="#c6bcb6"
      startIndex={0}
    />
  );
};

export default AdminDepanner;