import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../../api/config';

const LoginForm = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [alert, setAlert] = useState<{ severity: 'error' | 'success'; message: string } | null>(null);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_BASE_URL}/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      if (!response.ok) {
        throw new Error('Identifiant ou mot de passe incorrect');
      }
      const data = await response.json();
      localStorage.setItem('token', data.token); // Stocker le token JWT dans le localStorage
      navigate('/'); // Rediriger vers la page Dashboard après connexion réussie
    } catch (error) {
      console.error('Login error:', error);
      if (error instanceof Error) {
        setAlert({ severity: 'error', message: error.message });
      } else {
        setAlert({ severity: 'error', message: 'Une erreur inconnue est survenue' });
      }
    }
  };

  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', my: 4, p: 3, bgcolor: '#f9f9f9' }}>
      <Typography variant="h5" gutterBottom>
        Se connecter
      </Typography>
      {alert && (
        <Alert severity={alert.severity} sx={{ marginTop: '8px' }}>
          {alert.message}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <TextField
          id="username"
          label="Identifiant"
          variant="outlined"
          fullWidth
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          id="password"
          label="Mot de passe"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Se connecter
        </Button>
      </form>
    </Box>
  );
};

export default LoginForm;
