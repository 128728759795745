import React from "react";
import Section from "../Section/Section";
import API_BASE_URL from '../../api/config';

const Climatisation = () => {
  return (
    <Section
      apiUrl={`${API_BASE_URL}/climatisation`}
      sectionTitleApiUrl={`${API_BASE_URL}/sectiontitles/climatisation`}
      color="#b1cbbb"
      startIndex={0}
    />
  );
};

export default Climatisation;