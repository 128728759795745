import HomePage from "../../components/HomePage/HomePage";
import AdminHomePage from "../../components/HomePage/AdminHomePage";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

interface DecodedToken {
    exp?: number;
    role?: string;
    [key: string]: any;
}

const checkAndClearLocalStorage = () => {
    const token = localStorage.getItem('token');
    if (token) {
        try {
            const decodedToken: DecodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;

            if (decodedToken.exp && decodedToken.exp < currentTime) {
                //console.log('Token expired');
                localStorage.removeItem('token');
                //console.log('Local storage after clearing expired token:', localStorage);
            } else {
                //console.log('Token is valid:', decodedToken);
            }
        } catch (error) {
            //console.error('Error decoding token:', error);
            localStorage.removeItem('token');
            //console.log('Local storage after removing invalid token:', localStorage);
        }
    } else {
        //console.log('No token found in local storage');
    }

    //console.log('Current local storage:', localStorage);
};

export const HomePageDisplay = () => {
const navigate = useNavigate();
const [isAdmin, setIsAdmin] = useState(false);

useEffect(() => {
    checkAndClearLocalStorage();

    const token = localStorage.getItem('token');
    //console.log('token:', token);
    if (token) {
        try {
            const decodedToken: any = jwtDecode(token); 
            //console.log('decodedToken:', decodedToken);
            if (decodedToken && decodedToken.role === 'admin') {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
                navigate('/login');
            }
        } catch (error) {
            //console.error('Error decoding token:', error);
            setIsAdmin(false);
            navigate('/homepage');
        }
    } else {
        setIsAdmin(false);
        navigate('/homepage');
    }
}, [navigate]);

    return(
        <>
            {isAdmin ? <AdminHomePage /> : <HomePage />}
        </>
    )
}

export default HomePageDisplay;
