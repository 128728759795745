import { Box } from "@mui/material";
import { Typography, Button, TextField, MenuItem, InputLabel, FormControl, Select, Alert } from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Loading from "../general/Loading"
import API_BASE_URL from '../../api/config';

interface SectionTitle {
    _id: string;
    nomsection: string;
    titre: string;
    description: string;
  }

const TitleBox = () => {

    const [sectionTitle, setSectionTitle] = useState<SectionTitle | null>(null);

    useEffect(() => {
    
        const fetchSectionTitle = async () => {
          try {
            const response = await fetch(`${API_BASE_URL}/sectiontitles/message`);
            if (!response.ok) {
              throw new Error("Failed to fetch section title");
            }
            const data = await response.json();
            setSectionTitle(data);
          } catch (error) {
            console.error("Error fetching section title:", error);
          }
        };
    
        fetchSectionTitle();
      }, [sectionTitle]);

    return (
        <Box 
            sx={{ 
                padding: '40px', 
                textAlign: 'left', 
                border: '1px solid #ccc', 
                borderRadius: '8px', 
                backgroundColor: '#f9f9f9', 
                marginBottom: '20px'
            }}
        >
            {sectionTitle && (
            <>
                <Typography variant="h4" component="h1" gutterBottom>
                {sectionTitle.titre}
                </Typography>
                <Typography variant="body1">{sectionTitle.description}</Typography>
            </>
            )}
            </Box>
    );
};

const FormBox = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        nom: '',
        prenom: '',
        societe: '',
        telephone: '',
        email: '',
        message: ''
    });

    const [objet, setObjet] = React.useState('');
    type AlertType = {
        severity: 'success' | 'info' | 'warning' | 'error';
        message: string;
      };
    const [alert, setAlert] = useState<AlertType | null>(null);
    const [loading, setLoading] = useState(false);
    

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setAlert(null);
        setLoading(true);

        // Vérifier si tous les champs requis sont remplis
        if (!formData.nom || !formData.prenom || !formData.telephone || !formData.email || !formData.message) {
            setAlert({ severity: 'error', message: 'Veuillez remplir tous les champs requis.' });
            setLoading(false);
            return;
        }

        try {
            const response = await axios.post(`${API_BASE_URL}/messages/submit`, formData, {
                headers: {
                    'Content-Type': 'application/json' // Spécifier le type de contenu pour FormData
                }
            });
            //console.log(formData); 
            // Gérer la réponse du backend ici si nécessaire
            setAlert({ severity: 'success', message: 'Message envoyée avec succès.' });
            navigate('/contact/confirmation');
            // Réinitialiser le formulaire après soumission réussie si nécessaire
            setFormData({
                nom: '',
                prenom: '',
                societe: '',
                telephone: '',
                email: '',
                message: ''
            });
        } catch (error) {
            setAlert({ severity: 'error', message: 'Erreur lors de l\'envoi de la candidature.' });
            console.error(error);
        } finally {
            setLoading(false); 
        }
    };

    return (
        <Box 
            sx={{ 
                padding: '40px', 
                textAlign: 'left', 
                border: '1px solid #ccc', 
                borderRadius: '8px', 
                backgroundColor: '#e0e0e0' // Fond gris
            }}
        >
            <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
                <TextField
                    fullWidth
                    label="Nom"
                    name="nom"
                    value={formData.nom}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Prénom"
                    name="prenom"
                    value={formData.prenom}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Société"
                    name="societe"
                    value={formData.societe}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Téléphone"
                    name="telephone"
                    value={formData.telephone}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    multiline
                    rows={6} //nombre de lignes 
                />
                {alert && (
                <Alert severity={alert.severity} sx={{ marginTop: '8px' }}>
                    {alert.message}
                </Alert>
                )}
                {loading && <Loading />} 
                <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ marginTop: '20px' }}>
                    Envoyer
                </Button>
            </Box>
        </Box>
    );
};

const Message = () => {
    return (
        <Box sx={{ padding: '20px' }}>
            <TitleBox />
            <FormBox />
        </Box>
    );
};

export default Message;