import Contact from '../../components/Contact/Contact';
import Appbar from '../../components/general/Appbar';
import Footer from '../../components/general/Footer';

export const ContactPage = () => {
    return (
        <>
            <Appbar />
            <Contact />
            <Footer />
        </>
    )
}

export default ContactPage