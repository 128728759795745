import React, { useEffect, useState } from 'react';
import Appbar from "../general/Appbar";
import Footer from "../general/Footer";
import { Box, Button, Typography } from '@mui/material';
import styles from "../../styles/HomePage/homepage.module.scss";
import axios from 'axios';
import API_BASE_URL from '../../api/config';

const CUSTOM_BREAKPOINT = 1100;
const LARGE_SCREEN_HEIGHT = 800;
const SMALL_SCREEN_HEIGHT = 450;

const AdminHomePage = () => {

    const logos = [
        '/qualifelec.png',
        '/mff.png',
        '/KNX.png',
        '/esser.png',
        '/daikin.jpg'
    ];
    
    const [sections, setSections] = useState([
        {
            id: 1,
            title: 'Entreprise',
            backgroundImage: '', // Placeholder, will be replaced with fetched image
            buttonLink: '/entreprise',
            buttonText: 'Découvrir',
            imageId: '' // Placeholder for image ID
        },
        {
            id: 2,
            title: 'Maintenance',
            backgroundImage: '',
            buttonLink: '/maintenance',
            buttonText: 'Découvrir',
            imageId: ''
        },
        {
            id: 3,
            title: 'Electricité',
            backgroundImage: '',
            buttonLink: '/electricite',
            buttonText: 'Découvrir',
            imageId: ''
        },
        {
            id: 4,
            title: 'Climatisation',
            backgroundImage: '',
            buttonLink: '/climatisation',
            buttonText: 'Découvrir',
            imageId: ''
        }
    ]);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/homepage`);
                const images = response.data;

                const updatedSections = sections.map((section, index) => {
                    if (images[index]) {
                        return {
                            ...section,
                            backgroundImage: `${API_BASE_URL}/${images[index].image}`,
                            imageId: images[index]._id
                        };
                    }
                    return section;
                });

                setSections(updatedSections);
            } catch (error) {
                console.error('Erreur lors de la récupération des images:', error);
            }
        };

        fetchImages();
    }, []);

    const handleImageChange = async (event: any, sectionId: any) => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
    
            const section = sections.find(sec => sec.id === sectionId);
    
            if (section) { // Vérifiez que la section existe avant de continuer
                try {
                    const response = await axios.put(`${API_BASE_URL}/homepage/${section.imageId}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    const updatedSections = sections.map(sec => {
                        if (sec.id === sectionId) {
                            return {
                                ...sec,
                                backgroundImage: `${API_BASE_URL}/${response.data.image}`
                            };
                        }
                        return sec;
                    });
    
                    setSections(updatedSections);
                } catch (error) {
                    console.error('Erreur lors de la mise à jour de l\'image:', error);
                }
            } else {
                console.error('Section non trouvée pour l\'ID:', sectionId);
            }
        }
    };

    return (
        <Box className={styles.homepageContainer}>
            <Appbar />
            <Box className={styles.voitureContainer}
                sx={{
                    backgroundImage: 'url("/voitures.jpeg")',
                    minHeight: {
                        xs: `${SMALL_SCREEN_HEIGHT}px`,
                        [`@media (min-width:${CUSTOM_BREAKPOINT}px)`]: {
                            minHeight: `${LARGE_SCREEN_HEIGHT}px`
                        }
                    },
                }}
            />
            <Box className={styles.logobar}>
                {logos.map((logo, index) => (
                    <Box
                        key={index}
                        component="img"
                        src={logo}
                        alt={`Qualification Logo ${index + 1}`}
                        className={styles.logo}
                    />
                ))}
            </Box>
            <Box className={styles.sectionsContainer}>
                {sections.map(section => (
                    <Box
                        key={section.id}
                        className={styles.section}
                        sx={{ backgroundImage: `url(${section.backgroundImage})` }}
                    >
                        <Typography variant="h4" className={styles.sectionTitle}>
                            {section.title}
                        </Typography>
                        <Button
                            variant="contained"
                            component="label"
                            sx={{ marginTop: '15px' }}
                        >
                            Modifier la photo
                            <input
                                type="file"
                                hidden
                                accept="image/*"
                                onChange={(e) => handleImageChange(e, section.id)}
                            />
                        </Button>
                    </Box>
                ))}
            </Box>
            <Footer />
        </Box>
    );
};

export default AdminHomePage;