import React from "react";
import AdminSection from "../Section/AdminSection";
import API_BASE_URL from '../../api/config';

const AdminEntretenir = () => {
  return (
    <AdminSection
      sectionsUrl={`${API_BASE_URL}/entretenir`}
      getTitleUrl={`${API_BASE_URL}/sectiontitles/entretenir`}
      putTitleUrl={`${API_BASE_URL}/sectiontitles`}
      color="#8ca3a3"
      startIndex={1}
    />
  );
};

export default AdminEntretenir;