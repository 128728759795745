import styles from "../../styles/components/loginRegister/loginregister.module.scss" 
import { Box, Typography, Button } from "@mui/material";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const LoginRegister = () => {
	
	const navigate = useNavigate();

	const handleLogout = () => {
		// Supprimer le token du local storage
		localStorage.removeItem('token');

		// Rediriger vers la page de connexion
		navigate('/');
	};

	return(
		<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 10 }}>
			<Typography variant="h4" gutterBottom>
				Bienvenue sur la page de connexion/inscription de l'application SET
			</Typography>
			<Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
				<Button
					component={Link}
					to="/login"
					variant="contained"
					color="primary"
				>
					Se connecter
				</Button>
				<Button variant="contained" color="secondary" onClick={handleLogout}>
					Se déconnecter
				</Button>
			</Box>
		</Box>

	)
}

export default LoginRegister
