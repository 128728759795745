import Confirmation from "../../components/Messagerie/Confirmation";
import Appbar from "../../components/general/Appbar";
import Footer from "../../components/general/Footer";

export const ConfirmationMessage = () => {
    return(
        <>
            <Appbar />
            <Confirmation 
            title="Merci de nous avoir contactés !" 
            description="Nous avons bien reçu votre message. Nous vous contacterons sous peu."
            />
            <Footer />
        </>
    )
}

export default ConfirmationMessage;