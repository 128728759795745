import { Box } from "@mui/material";
import { Typography } from "@mui/material";

const Contact = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '70vh', // Hauteur minimale de l'écran
        width: '100vw', // Largeur de l'écran
      }}
    >
      
      {/* Box pour le titre et la carte */}
      <Box
        sx={{
          width: '80%', // Largeur fixe à 80% de la largeur de l'écran
          maxWidth: '1200px', // Largeur maximale de 600 pixels
          padding: '30px',
          textAlign: 'left',
          border: '1px solid #ccc',
          borderRadius: '8px',
          backgroundColor: '#f9f9f9',
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Où est localisée la SET ?
        </Typography>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2888.952295053722!2d6.948741915424153!3d43.60753487912298!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12cc284e4a3a5e13%3A0x96015c3c933264fc!2sSociete+Electro-thermique!5e0!3m2!1sfr!2sfr!4v1532103311764"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
        ></iframe>
      </Box>

    </Box>
  );
};

export default Contact;
