import React, { useState } from "react";
import { Box, Button, TextField, Typography, InputLabel } from "@mui/material";

const AddSectionForm = ({
  apiUrl,
  onAddSection,
  onCancel,
  position
}: {
  apiUrl: string;
  onAddSection: (section: any) => void;
  onCancel: () => void;
  position: number;
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image1, setImage1] = useState<File | null>(null);
  const [image2, setImage2] = useState<File | null>(null);
  const [error, setError] = useState("");

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!image1 && !image2) {
      setError("Veuillez télécharger au moins une image.");
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("position", position.toString());
    
    if (image1) formData.append("images", image1);
    if (image2) formData.append("images", image2);

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Failed to add section: ${response.statusText}`);
      }

      const newSection = await response.json();
      onAddSection(newSection); // Callback to update the parent component with the new section
      setError("");
    } catch (error) {
      console.error("Error adding section:", error);
      setError("Une erreur s'est produite lors de l'ajout de la section.");
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, setImage: React.Dispatch<React.SetStateAction<File | null>>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setError("Veuillez sélectionner un fichier image valide (JPEG ou PNG).");
        setImage(null);
      } else {
        setError("");
        setImage(file);
      }
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ margin: "20px 0" }}>
      <TextField
        label="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
        sx={{ marginBottom: "10px" }}
      />
      <TextField
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        fullWidth
        sx={{ marginBottom: "10px" }}
      />
      <Box sx={{ marginBottom: "10px" }}>
        <InputLabel htmlFor="upload-image1">Image 1</InputLabel>
        <input
          id="upload-image1"
          type="file"
          accept="image/*"
          onChange={(e) => handleFileChange(e, setImage1)}
          style={{ display: "block", marginTop: "8px" }}
        />
      </Box>
      <Box sx={{ marginBottom: "10px" }}>
        <InputLabel htmlFor="upload-image2">Image 2</InputLabel>
        <input
          id="upload-image2"
          type="file"
          accept="image/*"
          onChange={(e) => handleFileChange(e, setImage2)}
          style={{ display: "block", marginTop: "8px" }}
        />
      </Box>
      <Box sx={{ marginTop: "8px", marginBottom: "16px" }}>
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "8px" }}>
        <Button type="submit" variant="contained" color="primary">
          Add Section
        </Button>
        <Button variant="contained" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default AddSectionForm;