import { AppBar, Avatar, Box, Button, Container, Divider, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ContactMailIcon from '@mui/icons-material/ContactMail'; 

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';

import styles from '../../styles/general/toolbarConnected.module.scss';

import * as React from 'react';

interface DecodedToken {
    exp?: number;
    role?: string;
    [key: string]: any; // Ajouter cette ligne pour permettre des propriétés supplémentaires
}

export default function ToolbarConnected(){

    const [anchorElNav, setAnchorElNav] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decodedToken: DecodedToken = jwtDecode(token);
                const currentTime = Date.now() / 1000;
                if (decodedToken.exp && decodedToken.exp < currentTime) {
                    localStorage.removeItem('token');
                } else if (decodedToken.role === 'admin') {
                    setIsAdmin(true);
                }
            } catch (error) {
                console.error('Error decoding token:', error);
                localStorage.removeItem('token');
            }
        }
    }, []);

    const handleMenuOpen = (event: any) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorElNav(null);
    };

    const handleHomepage = () => {
        handleMenuClose();
        navigate(`/`);
    }

    const handleLocation = () => {
        handleMenuClose();
        navigate(`/location`);
    }

    const handleJoinUs = () => {
        handleMenuClose();
        navigate(`/join-us`);
    }

    const handleMessage = () => {
        handleMenuClose();
        navigate(`/contact`);
    }

    const handleMaintenance = () => {
        handleMenuClose();
        navigate(`/maintenance`);
    }

    const handleClimatisation = () => {
        handleMenuClose();
        navigate(`/climatisation`);
    }

    const handleElectricite = () => {
        handleMenuClose();
        navigate(`/electricite`);
    }

    const handleRealisation = () => {
        handleMenuClose();
        navigate(`/realisations`);
    }

    const handleEntretenir = () => {
        handleMenuClose();
        navigate(`/entretenir`);
    }

    const handleSecuriser = () => {
        handleMenuClose();
        navigate(`/securiser`);
    }

    const handleDepanner = () => {
        handleMenuClose();
        navigate(`/depanner`);
    }

    const handleEntreprise = () => {
        handleMenuClose();
        navigate(`/entreprise`);
    }

    const handleAdmin = () => {
        handleMenuClose();
        navigate(`/connexion`);
    }

    const pages = [
        {
            name: 'Nos réalisations',
            function : handleRealisation
        },
        {
            name: 'Electricité',
            function : handleElectricite
        },
        {
            name: 'Climatisation',
            function : handleClimatisation
        },
        {
            name: 'Maintenance',
            function : handleMaintenance
        },
        {
            name: 'Nous rejoindre',
            function : handleJoinUs
        }
    ]

    if (isAdmin) {
        pages.push({ name: 'Depanner', function: handleDepanner });
        pages.push({ name: 'Entretenir', function: handleEntretenir });
        pages.push({ name: 'Securiser', function: handleSecuriser });
        pages.push({ name: 'Entreprise', function: handleEntreprise });
        pages.push({ name: 'Administrateur', function: handleAdmin });
    }
    
    return (
        <AppBar position="static">
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={handleMenuOpen}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="pages-menu"
                        anchorEl={anchorElNav}
                        open={Boolean(anchorElNav)}
                        onClose={handleMenuClose}
                    >
                        {pages.map((item, index) => (
                        <MenuItem key={index} onClick={item.function}>
                            <Typography textAlign="center">{item.name}</Typography>
                        </MenuItem>
                ))}
                    </Menu>
                

                    <IconButton color="inherit" className={styles.logo} onClick={handleHomepage} sx={{ ml: 0, mr: 2 }}>
                            <img
                                src="/set-logo2.png"
                                alt="SET Logo"
                                style={{ height: '40px' }}
                            />
                    </IconButton>
                </Box>
        
                <Divider orientation="vertical" flexItem sx={{ 
                        bgcolor: 'white', 
                        mx: 2, 
                        display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' } 
                    }} />

                <Box sx={{
                    display: 'none', // Par défaut, les boutons ne sont pas affichés
                    flexGrow: 1,
                    justifyContent: 'space-between',
                    '@media (min-width: 1200px)': { // Media query pour les écrans de taille MD (900px) et plus grands
                        display: 'flex', // Affiche les boutons seulement lorsque la taille de l'écran est MD ou plus grande
                    }
                }}>
                    {pages.map((item, index) => (
                        <Button 
                            key={index}
                            onClick={item.function} 
                            color="inherit" 
                             sx={{ 
                            minWidth: 'auto', 
                            padding: '0 8px', 
                            margin: '0 4px',
                            ...(index === pages.length - 1 && { marginRight: 'auto' }) // Add auto margin to the last item
                        }} 
                        >
                            {item.name}
                        </Button>
                    ))}
                </Box>
               
               
                <Divider orientation="vertical" flexItem sx={{ 
                        bgcolor: 'white', 
                        mx: 2, 
                        display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' } 
                    }} />
           
                
                <Box sx={{ display: 'flex' }}>
                        <IconButton color="inherit" onClick={handleLocation}>
                            <LocationOnIcon />
                        </IconButton>
                        <IconButton color="inherit" onClick={handleMessage}>
                            <ContactMailIcon />
                        </IconButton>
                    </Box>
            </Toolbar>
        </AppBar> 
    );
}
