import { Box, Typography, TextField, Button, Alert } from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from 'axios';
import API_BASE_URL from '../../api/config';

const EmailSettingsBox = () => {
    const [email, setEmail] = useState<string>('');
    const [newEmail, setNewEmail] = useState<string>('');
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [success, setSuccess] = useState<string>('');

    useEffect(() => {
        // Récupérer l'email actuel depuis le backend
        const fetchEmailRecipient = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/settings/email-recipient`);
                setEmail(response.data.emailRecipient);
                setNewEmail(response.data.emailRecipient);
            } catch (error) {
                console.error("Erreur lors de la récupération de l'email :", error);
            }
        };

        fetchEmailRecipient();
    }, []);

    const handleUpdateEmail = async () => {
        try {
            const response = await axios.put(`${API_BASE_URL}/settings/email-recipient`, { email: newEmail });
            if (response.status === 200) {
                setEmail(newEmail);
                setSuccess("Email mis à jour avec succès.");
                setIsEditing(false);
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour de l'email :", error);
            setError("Erreur lors de la mise à jour de l'email.");
        }
    };

    return (
        <Box 
            sx={{ 
                padding: '40px', 
                textAlign: 'left', 
                border: '1px solid #ccc', 
                borderRadius: '8px', 
                backgroundColor: '#f9f9f9', 
                marginBottom: '20px'
            }}
        >
            <Typography variant="h4" component="h1" gutterBottom>
                Gestion de l'Email de Réception
            </Typography>
            {isEditing ? (
                <>
                    <TextField
                        label="Nouvel Email"
                        value={newEmail}
                        onChange={(e) => setNewEmail(e.target.value)}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                    />
                    {error && <Alert severity="error" sx={{ marginTop: '8px' }}>{error}</Alert>}
                    {success && <Alert severity="success" sx={{ marginTop: '8px' }}>{success}</Alert>}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUpdateEmail}
                        sx={{ marginTop: '10px', marginRight: '10px' }}
                    >
                        Enregistrer
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setIsEditing(false)}
                        sx={{ marginTop: '10px' }}
                    >
                        Annuler
                    </Button>
                </>
            ) : (
                <>
                    <Typography variant="body1">
                        Email actuel : {email}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setIsEditing(true)}
                        sx={{ marginTop: '20px' }}
                    >
                        Modifier
                    </Button>
                </>
            )}
        </Box>
    );
};

export default EmailSettingsBox;