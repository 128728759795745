import React from "react";
import AdminSection from "../Section/AdminSection";
import API_BASE_URL from '../../api/config';

const AdminElectricite = () => {
  return (
    <AdminSection
      sectionsUrl={`${API_BASE_URL}/electricite`}
      getTitleUrl={`${API_BASE_URL}/sectiontitles/electricite`}
      putTitleUrl={`${API_BASE_URL}/sectiontitles`}
      color="#92a8d1"
      startIndex={1}
    />
  );
};

export default AdminElectricite;